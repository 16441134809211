import { createLogger, createStore } from 'vuex'

import user from './modules/user'
import role from './modules/role'
import company from './modules/company'
import auth from './modules/auth'
import currency from './modules/currency'
import tag from './modules/tag'
import operatingZone from './modules/operatingZone'
import account from './modules/account'
import sector from './modules/sector'
import client from './modules/client'
import localClient from './modules/localClient'
import entityBillingSoftware from './modules/entityBillingSoftware'
import connector from './modules/connector'
import breadcrumb from './modules/breadcrumb'
import contract from './modules/contract'
import product from './modules/product'
import programManagement from './modules/programManagement'
import quickbook from './modules/quickbook'
import contact from './modules/contact'
import localProductType from './modules/localProductType'
import platform from './modules/platform'
import localProduct from './modules/localProduct'
import services from './modules/services'
import production from './modules/production'
import productionLocalClient from './modules/productionLocalClient'
import postproduction from './modules/postproduction'
import externalPostProduction from './modules/externalPostProduction'
import prebilling from './modules/prebilling'
import sales from './modules/sales'
import interco from './modules/interco'
import budget from './modules/budget'
import logging from './modules/logging'
import internalProject from '@/store/modules/internalProject'
import simpleProject from '@/store/modules/simpleProject'
import clientProvider from '@/store/modules/clientProvider'
import internalCost from '@/store/modules/internalCost'
import externalproduction from '@/store/modules/externalproduction'
import referenceExchangeRate from '@/store/modules/referenceExchangeRate'
import pivotTableConfig from '@/store/modules/pivotTableConfig'
import apiToken from '@/store/modules/apiToken'
import clientAdvertiser from '@/store/modules/clientAdvertiser'
import feature from '@/store/modules/feature'
import jedoxReportingLine from '@/store/modules/jedoxReportingLine'
import importTemplate from '@/store/modules/importTemplate'
import externalThirdParties from '@/store/modules/externalThirdParties'
import scriptRunner from '@/store/modules/scriptRunner'
import scriptCommand from '@/store/modules/scriptCommand'
import scriptRunnerLog from '@/store/modules/scriptRunnerLog'

const debug = process.env.NODE_ENV !== 'production'

const store = createStore({
  state: {
    message: null,
  },
  actions: {
    clearMessage({ commit }) {
      commit('CLEAR_MESSAGE')
    },
    setMessage({ commit }, payload) {
      commit('SET_MESSAGE', payload)
    },
  },
  mutations: {
    CLEAR_MESSAGE(state) {
      state.message = null
    },
    SET_MESSAGE(state, payload) {
      state.message = payload
    },
  },
  getters: {
    message(state) {
      return state.message
    },
  },
  modules: {
    user,
    role,
    company,
    auth,
    currency,
    tag,
    operatingZone,
    account,
    sector,
    client,
    localClient,
    entityBillingSoftware,
    connector,
    breadcrumb,
    contract,
    product,
    programManagement,
    quickbook,
    contact,
    localProductType,
    platform,
    localProduct,
    simpleProject,
    services,
    internalProject,
    production,
    productionLocalClient,
    postproduction,
    prebilling,
    sales,
    interco,
    clientProvider,
    budget,
    internalCost,
    externalproduction,
    logging,
    referenceExchangeRate,
    pivotTableConfig,
    apiToken,
    clientAdvertiser,
    feature,
    jedoxReportingLine,
    externalPostProduction,
    importTemplate,
    externalThirdParties,
    scriptRunner,
    scriptCommand,
    scriptRunnerLog,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
})

export default store
