import { helpers } from '@vuelidate/validators'
import { sift, unique } from 'radash'

export const enumValidator = (enums) => {
  return function (value) {
    return enums.indexOf(value) !== -1
  }
}

export const customUrlRule = (value) => {
  return (
    !helpers.req(value) ||
    value.startsWith('http://') ||
    value.startsWith('https://')
  )
}

export const booleanValidator = (value) =>
  !helpers.req(value) || typeof value === 'boolean'

export const uniqueInList = () =>
  helpers.withParams({ type: 'uniqueInList' }, (value) => {
    if (!value || !Array.isArray(value)) return true
    const uniqueValues = sift(
      unique(value.map((item) => item?.externalThirdParty?._id)),
    )

    return uniqueValues.length === value.length
  })

export const validateAtLeastTwoCompaniesOnPM = (form) => {
  const fields = ['pivot', 'admin', 'sales_support', 'producers']

  const companies = fields.flatMap((field) =>
    form[field] ? [].concat(form[field]) : [],
  )

  const uniqueCompanies = [...new Set(companies.filter(Boolean))]

  return uniqueCompanies.length > 1
}
