export const breadcrumbs = {
  DASHBOARD: [{ label: 'Dashboard' }],
  USER: [{ label: 'User' }],
  COMPANY: [{ label: 'Company' }],
  CURRENCY: [{ label: 'Currency' }],
  QUICKBOOK: [{ label: 'Quickbook' }],
  LOCAL_SOFTWARE: [{ label: 'Local Software' }],
  ROLE: [{ label: 'Role' }],
  SECTOR: [{ label: 'Sector' }],
  CLIENT: [{ label: 'Client' }],
  LOCAL_CLIENT: [{ label: 'Local Client' }],
  CONTRACT: [{ label: 'Contract' }],
  PRODUCT: [{ label: 'Product' }],
  PROGRAM_MANAGEMENT: [{ label: 'Program Management' }],
  TAG: [{ label: 'Tag' }],
  OPERATING_ZONE: [{ label: 'Operating Zone' }],
  EXTERNAL_THIRD_PARTIES: [{ label: 'External Third Parties' }],
  CLIENT_PROVIDER: [{ label: 'Client Provider' }],
  CONTACT: [{ label: 'Contact' }],
  LOCAL_PRODUCT_TYPE: [{ label: 'Local Product Type' }],
  PLATFORM: [{ label: 'Platform' }],
  LOCAL_PRODUCT: [{ label: 'Local Product' }],
  SIMPLE_PROJECT: [{ label: 'Simple Project' }],
  TEMPLATE: [{ label: 'Templates' }],
  IMPORT_TEMPLATE: [{ label: 'Import Template' }],
  SERVICES: [{ label: 'Services' }],
  INTERNAL_PROJECT: [{ label: 'Internal Project' }],
  PRODUCTION: [{ label: 'Production' }],
  POSTPRODUCTION: [{ label: 'Post-Production' }],
  PREBILLING: [{ label: 'Pre-Billing' }],
  SALES: [{ label: 'Sales' }],
  PRODUCTIONS: [{ label: 'Productions' }],
  FEE: [{ label: 'fee' }],
  INTERCO: [{ label: 'Interco' }],
  DECLINATION_MASTER_PRODUCTS: [{ label: 'My declination of master products' }],
  BUDGET: [{ label: 'Budget' }],
  BUDGET_ENTRIES: [{ label: 'Budget Entries' }],
  BUDGET_DASHBOARD: [{ label: 'Budget Dashboard' }],
  INTERNAL_COST: [{ label: 'Internal Cost' }],
  EXTERNAL_PRODUCTION: [{ label: 'External Production' }],
  EXTERNAL_POST_PRODUCTION: [{ label: 'External Post Production' }],
  LOGGING: [{ label: 'Logging' }],
  REFERENCE_EXCHANGE_RATES: [{ label: 'Reference Exchange Rates' }],
  REAL: [{ label: 'Real' }],
  API_TOKEN: [{ label: 'API Tokens' }],
  CLIENT_ADVERTISER: [{ label: 'Client vs Advertiser' }],
  FEATURE: [{ label: 'Feature' }],
  JEDOX_REPORTING_LINE: [{ label: 'Jedox Reporing Line' }],
  SCRIPT_RUNNER: [{ label: 'Script runner' }],
  SCRIPT_COMMAND: [{ label: 'Script command' }],
  PRICING_RULES_1: [{ label: 'Pricing Rules 1' }],
  PRICING_RULES_2: [{ label: 'Pricing Rules 2' }],
}
