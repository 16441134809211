import BaseService from './BaseService'
import { protectedRequest } from '@/boot/axios'

class ScriptRunnerService extends BaseService {
  constructor() {
    super('scripts-commands')
  }

  exec(id, options) {
    return protectedRequest({
      method: 'POST',
      url: `${this._apiUrl}/${id}/exec`,
      data: { options },
    })
  }

  getJobStatus(id, executionId) {
    return protectedRequest({
      method: 'GET',
      url: `${this._apiUrl}/${id}/get-job-status`,
      params: { executionId },
    })
  }
}

export default new ScriptRunnerService()
