import { createApp, h, reactive } from 'vue'

import { setupCalendar } from 'v-calendar'
import vue3GoogleLogin from 'vue3-google-login'

import { abilitiesPlugin } from '@casl/vue'
import { Ability } from '@casl/ability'

import { createIntl } from 'vue-intl'

import PrimeVue from 'primevue/config'
import AutoComplete from 'primevue/autocomplete'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Avatar from 'primevue/avatar'
import AvatarGroup from 'primevue/avatargroup'
import Badge from 'primevue/badge'
import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
import Breadcrumb from 'primevue/breadcrumb'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import CascadeSelect from 'primevue/cascadeselect'
import Carousel from 'primevue/carousel'
import Chart from 'primevue/chart'
import Checkbox from 'primevue/checkbox'
import Chip from 'primevue/chip'
import Chips from 'primevue/chips'
import ColorPicker from 'primevue/colorpicker'
import Column from 'primevue/column'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmationService from 'primevue/confirmationservice'
import ContextMenu from 'primevue/contextmenu'
import DataTable from 'primevue/datatable'
import DataView from 'primevue/dataview'
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Dropdown from 'primevue/dropdown'
import Editor from 'primevue/editor'
import Fieldset from 'primevue/fieldset'
import FileUpload from 'primevue/fileupload'
import FullCalendar from '@fullcalendar/vue3'
import Galleria from 'primevue/galleria'
import InlineMessage from 'primevue/inlinemessage'
import Inplace from 'primevue/inplace'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import IconField from 'primevue/iconfield'
import InputIcon from 'primevue/inputicon'
import FloatLabel from 'primevue/floatlabel'
import InputOtp from 'primevue/inputotp'
import Stepper from 'primevue/stepper'
import StepperPanel from 'primevue/stepperpanel'
import MeterGroup from 'primevue/metergroup'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import Knob from 'primevue/knob'
import Listbox from 'primevue/listbox'
import MegaMenu from 'primevue/megamenu'
import Menu from 'primevue/menu'
import Menubar from 'primevue/menubar'
import Message from 'primevue/message'
import MultiSelect from 'primevue/multiselect'
import OrderList from 'primevue/orderlist'
import OrganizationChart from 'primevue/organizationchart'
import OverlayPanel from 'primevue/overlaypanel'
import Paginator from 'primevue/paginator'
import Panel from 'primevue/panel'
import PanelMenu from 'primevue/panelmenu'
import Password from 'primevue/password'
import PickList from 'primevue/picklist'
import ProgressBar from 'primevue/progressbar'
import ProgressSpinner from 'primevue/progressspinner'
import Rating from 'primevue/rating'
import RadioButton from 'primevue/radiobutton'
import Ripple from 'primevue/ripple'
import SelectButton from 'primevue/selectbutton'
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop'
import Skeleton from 'primevue/skeleton'
import Slider from 'primevue/slider'
import Sidebar from 'primevue/sidebar'
import SpeedDial from 'primevue/speeddial'
import SplitButton from 'primevue/splitbutton'
import Splitter from 'primevue/splitter'
import SplitterPanel from 'primevue/splitterpanel'
import Steps from 'primevue/steps'
import StyleClass from 'primevue/styleclass'
import TabMenu from 'primevue/tabmenu'
import TieredMenu from 'primevue/tieredmenu'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Toolbar from 'primevue/toolbar'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Tag from 'primevue/tag'
import Timeline from 'primevue/timeline'
import ToggleButton from 'primevue/togglebutton'
import Tooltip from 'primevue/tooltip'
import Tree from 'primevue/tree'
import TreeSelect from 'primevue/treeselect'
import TreeTable from 'primevue/treetable'
import TriStateCheckbox from 'primevue/tristatecheckbox'
import DialogService from 'primevue/dialogservice'
import { FilterService } from 'primevue/api'
import Row from 'primevue/row'
import ColumnGroup from 'primevue/columngroup'
import CodeDiff from 'v-code-diff'
import VueGtag from 'vue-gtag'
import AppWrapper from './AppWrapper.vue'

import 'primevue/resources/primevue.min.css'
import 'primevue/resources/themes/aura-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'
import 'prismjs/themes/prism-coy.css'
import 'v-calendar/dist/style.css'
import './styles.css'

import i18n from './i18n'
import router from './router'
import store from './store'
import { FilterClose } from './common/directives/FilterClose.directive'
import config from '@/config'
import * as filtersUtils from '@/common/utils/filters.utils'
import { customFilters } from '@/common/constants/filter.constant'
import luxon from '@/plugins/luxon'

const app = createApp({
  render() {
    return h(AppWrapper)
  },
})

app.use(i18n)

app.use(PrimeVue, {
  ripple: true,
  inputStyle: 'outlined',
})
app.use(ConfirmationService)
app.use(ToastService)
app.use(DialogService)
app.use(store)

app.use(
  VueGtag,
  {
    config: { id: config.VUE_APP_GOOGLE_ANALYTICS_ID },
    appName: config.VUE_APP_GOOGLE_ANALYTICS_STREAM_NAME,
    pageTrackerScreenviewEnabled: true,
    pageTrackerEnabled: true,
    pageTrackerTemplate: (to) => {
      return {
        page_title: to.name,
        page_path: to.path,
        page_location: window.location.href,
      }
    },
  },
  router,
)
app.use(router)
app.use(CodeDiff)
app.use(abilitiesPlugin, new Ability(), {
  useGlobalProperties: true,
})
app.use(luxon)

app.use(setupCalendar, {})

// Google Auth Api initialisation
app.use(vue3GoogleLogin, {
  clientId: config.GOOGLE_CLIENT_ID,
})

app.use(
  createIntl({
    locale: navigator?.language ?? 'en',
    defaultLocale: 'en',
  }),
)

app.config.globalProperties.$appState = reactive({
  RTL: false,
  isNewThemeLoaded: false,
  layoutMode: 'light',
})
app.config.globalProperties.$filters = filtersUtils

FilterService.register(customFilters.IS_EMPTY, () => {
  // PLACEHOLDER
})

app.directive('tooltip', Tooltip)
app.directive('badge', BadgeDirective)
app.directive('ripple', Ripple)
app.directive('filter-close-icon', FilterClose)
app.directive('styleclass', StyleClass)

/* eslint-disable vue/multi-word-component-names, vue/no-reserved-component-names */
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('AutoComplete', AutoComplete)
app.component('Avatar', Avatar)
app.component('AvatarGroup', AvatarGroup)
app.component('Badge', Badge)
app.component('Breadcrumb', Breadcrumb)
app.component('Button', Button)
app.component('Calendar', Calendar)
app.component('Card', Card)
app.component('Carousel', Carousel)
app.component('CascadeSelect', CascadeSelect)
app.component('Chart', Chart)
app.component('Checkbox', Checkbox)
app.component('Chip', Chip)
app.component('Chips', Chips)
app.component('ColorPicker', ColorPicker)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('ConfirmDialog', ConfirmDialog)
app.component('ConfirmPopup', ConfirmPopup)
app.component('ContextMenu', ContextMenu)
app.component('DataTable', DataTable)
app.component('DataView', DataView)
app.component('DataViewLayoutOptions', DataViewLayoutOptions)
app.component('Dialog', Dialog)
app.component('Divider', Divider)
app.component('Dropdown', Dropdown)
app.component('Editor', Editor)
app.component('Fieldset', Fieldset)
app.component('FileUpload', FileUpload)
app.component('FullCalendar', FullCalendar)
app.component('Galleria', Galleria)
app.component('InlineMessage', InlineMessage)
app.component('Inplace', Inplace)
app.component('InputMask', InputMask)
app.component('InputNumber', InputNumber)
app.component('InputSwitch', InputSwitch)
app.component('InputText', InputText)
app.component('IconField', IconField)
app.component('InputIcon', InputIcon)
app.component('FloatLabel', FloatLabel)
app.component('InputOtp', InputOtp)
app.component('Stepper', Stepper)
app.component('StepperPanel', StepperPanel)
app.component('MeterGroup', MeterGroup)
app.component('Knob', Knob)
app.component('Listbox', Listbox)
app.component('MegaMenu', MegaMenu)
app.component('Menu', Menu)
app.component('Menubar', Menubar)
app.component('Message', Message)
app.component('MultiSelect', MultiSelect)
app.component('OrderList', OrderList)
app.component('OrganizationChart', OrganizationChart)
app.component('OverlayPanel', OverlayPanel)
app.component('Paginator', Paginator)
app.component('Panel', Panel)
app.component('PanelMenu', PanelMenu)
app.component('Password', Password)
app.component('PickList', PickList)
app.component('ProgressBar', ProgressBar)
app.component('ProgressSpinner', ProgressSpinner)
app.component('RadioButton', RadioButton)
app.component('Rating', Rating)
app.component('Row', Row)
app.component('SelectButton', SelectButton)
app.component('ScrollPanel', ScrollPanel)
app.component('ScrollTop', ScrollTop)
app.component('Slider', Slider)
app.component('Sidebar', Sidebar)
app.component('Skeleton', Skeleton)
app.component('SpeedDial', SpeedDial)
app.component('SplitButton', SplitButton)
app.component('Splitter', Splitter)
app.component('SplitterPanel', SplitterPanel)
app.component('Steps', Steps)
app.component('TabMenu', TabMenu)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Tag', Tag)
app.component('Textarea', Textarea)
app.component('TieredMenu', TieredMenu)
app.component('Timeline', Timeline)
app.component('Toast', Toast)
app.component('Toolbar', Toolbar)
app.component('ToggleButton', ToggleButton)
app.component('Tree', Tree)
app.component('TreeSelect', TreeSelect)
app.component('TreeTable', TreeTable)
app.component('TriStateCheckbox', TriStateCheckbox)
/* eslint-enable vue/multi-word-component-names, vue/no-reserved-component-names */

app.mount('#app')

export default store
